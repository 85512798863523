// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import $ from 'jquery';
import "bootstrap";                                   // Bootstrap4
//import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons";        // Bootstrap Icons
import Chart from "chart.js/auto";                    // ChartJS
import ChartDataLabels from "chartjs-plugin-datalabels";
import Select2 from "select2";                        // Select2
import "select2/dist/css/select2.min.css"
import "select2-bootstrap4-theme/dist/select2-bootstrap4.min.css"
import JsBarcode from "jsbarcode";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css"
import "../stylesheets/application";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Enable $ for jQuery shortcut
window.jQuery = $;
window.$ = $;

// Bootstrap
document.addEventListener("turbolinks:before-cache", function(){
  $(".combo-select").select2('destroy');
  $(".combo-select-tags").select2('destroy');
});
document.addEventListener("turbolinks:load", function() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    Dropzone.autoDiscover = false;

    // Make table rows clickable via ajax
    $("#datatable").on('click', ".table tbody tr", function(event) {
      var target = $(event.target);
      console.log(event.target)
      if (target.is(":not(a)") && target.is(":not(icon)")) {
        var href = $(this).find("a").first().attr("href");
        if(href) {
          $.ajax({
            type: 'GET',
            url: href + ".js",
            success: function(){ JsBarcode(".barcode").init(); }, // render barcode if found
          });
        }
      }
    });
  });
});

// ChartJS
global.Chart = Chart;
Chart.register(ChartDataLabels);

global.showChart = function(id){
  var ctx = document.getElementById(id).getContext('2d');
  var backgroundColor = null;
  var options = JSON.parse(ctx.canvas.dataset.options);
  console.log(options);

  if(ctx.canvas.dataset.type == 'bar'){
    backgroundColor = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#a195cd", "#a15ea2", "#a1ba9f", "#a8c4b9", "#a45450"];
  }else if(ctx.canvas.dataset.type == 'line'){
    backgroundColor = ["#3e95cd"];
  }

  var myChart = new Chart(ctx, {
      type: ctx.canvas.dataset.type,
      options:  options,
      data: {
          labels: JSON.parse(ctx.canvas.dataset.labels),
          datasets: [{
            label: ctx.canvas.dataset.label,
            data: JSON.parse(ctx.canvas.dataset.data),
            backgroundColor: backgroundColor,
            datalabels: {
              display: false
            }
          }]
      },
    });
};

global.showPieChart = function(id){
  var ctx = document.getElementById(id).getContext('2d');
  var backgroundColor = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#a195cd", "#a15ea2", "#a1ba9f", "#a8c4b9", "#a45450"];
  var options = {
    legend: {
      display: true
    },
    plugins: {
      datalabels: {
        formatter: function(value, ctx){
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = (value*100 / sum).toFixed(2)+"%";
          return percentage;
        },
        color: "#FFFFFF",
      }
    }
  }

  var myChart = new Chart(ctx, {
      type: ctx.canvas.dataset.type,
      options:  options,
      data: {
          labels: JSON.parse(ctx.canvas.dataset.labels),
          datasets: [{
            label: ctx.canvas.dataset.label,
            data: JSON.parse(ctx.canvas.dataset.data),
            backgroundColor: backgroundColor
          }]
      },
    });
};

// DropZoneJS
global.Dropzone = Dropzone;

global.wait = function (ms){
   var start = new Date().getTime();
   var end = start;
   while(end < start + ms) {
     end = new Date().getTime();
  }
}

// Select2
global.initialize_combo_select = function(){
  $(".combo-select").select2({
    dropdownParent: $("#showModal"),
    theme: 'bootstrap4',
  });
  /*
   * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
   * see: https://github.com/select2/select2/issues/5993
   * see: https://github.com/jquery/jquery/issues/4382
   *
   * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
   */

  $(document).on('select2:open', () => {
    document.querySelector('.select2-container--open .select2-search__field').focus();
  });
}

global.initialize_combo_select_tags = function(){
  $(".combo-select-tags").select2({
    dropdownParent: $("#showModal"),
    tags: true,
    tokenSeparators: [','],
    width: '100%',
  });
}

// JsBarcode
global.initialize_jsbarcode = function(){
  JsBarcode(".barcode").init(); // render barcode if found
}
